
:root {
  --planet-overlay-opacity: 0.3;
}

div.planets .solar_systm .planet, div.planets .planet-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
  }
  
  div.planets .solar_systm .planet {
    height: 1200px;
    width: 1200px;
    border-radius: 600px;
    background: red;
    transition: transform 2.8s 0.23s cubic-bezier(0.33, 0, 0, 1), opacity 2s 0.8s, box-shadow 0s 0s;
    background-size: 1140px 910px !important;
    top: auto;
    bottom: -920px;
  }

  div.planets .solar_systm .planet .moon {
    height: 200px;
    width: 200px;
    border-radius: 600px;
    background: red;
    position: absolute;
    text-align: center;
    color: white;
    text-transform: uppercase;
    opacity: 0;
    transition: all 0.6s 0.2s;
  }
  div.planets .solar_systm .planet .moon h2 {
    font-weight: 100;
    font-size: 40px;
    letter-spacing: 5px;
    margin: 0;
    position: relative;
    top: -120px;
  }
  div.planets .solar_systm .planet .moon h3 {
    font-weight: 100;
    font-size: 20px;
    letter-spacing: 5px;
    color: #fea082;
    margin: 0;
    position: relative;
    top: -120px;
  }
  @-webkit-keyframes planet {
    from {
      background-position-y: 0px;
    }
    to {
      background-position-y: -1000px;
    }
  }
  @keyframes planet {
    from {
      background-position-y: 0px;
    }
    to {
      background-position-y: -1000px;
    }
  }
  
  div.planets .solar {
    position: absolute;
    transform: rotatex(-20deg);
    perspective: 800px;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
  }
  
  div.planets {
    height: 100vh;
    max-height: 100vh !important;
    max-width: 100vw;
    width: 100vw !important;
    position: absolute;
    bottom: 0 !important;
    overflow: hidden !important;
  }
  
  div.planets .planet-overlay {
    border-bottom: 1020px solid #00000000;
    width: 1800px;
    height: 620px;
    top: auto;
    transform: none;
    top: -240px;
    left: -303px;
    opacity: 1;
    border-radius: 100%;
    z-index: 0;
    box-shadow: 0px -190px 215px 110px black inset;
  }

  
  div.planets .solar_systm:nth-of-type(1) .planet {
    transform: translateZ(0px) translateY(-1050px) rotatex(4deg) scalex(1);
    opacity: 2;
  }

  @media screen and (max-width: 1000px) {
    div.planets .solar_systm:nth-of-type(1) .planet {
      transform: translateZ(0px) translateY(-1050px) rotatex(4deg) scalex(1.05);
      opacity: 2;
    }
  }

  div.planets .solar_systm:nth-of-type(1) .planet_description {
    opacity: 1;
  }
  div.planets .solar_systm:nth-of-type(1) .planet_description p, div.planets .solar_systm:nth-of-type(1) .planet_description a {
    opacity: 0;
  }
  div.planets .solar_systm:nth-of-type(1) .planet_description h1, div.planets .solar_systm:nth-of-type(1) .planet_description h2 {
    position: relative;
    top: -330px;
    transition: all 0.5s 0s;
  }
  div.planets .solar_systm:nth-of-type(2) .planet {
    transform: translateZ(-2300px) translateY(0) rotatex(4deg) scalex(0.89);
    opacity: 1;
  }
  div.planets .solar_systm:nth-of-type(2) .planet_description {
    opacity: 1;
  }
  div.planets .solar_systm:nth-of-type(2) .planet_description p, div.planets .solar_systm:nth-of-type(2) .planet_description a {
    opacity: 0;
  }
  div.planets .solar_systm:nth-of-type(2) .planet_description h1, div.planets .solar_systm:nth-of-type(2) .planet_description h2 {
    position: relative;
    top: -330px;
    transition: all 0.5s 0s;
  }
  div.planets .solar_systm:nth-of-type(3) .planet {
    transform: translateZ(-4600px) translateY(0) rotatex(4deg) scalex(0.89);
    opacity: 0;
  }
  div.planets .solar_systm:nth-of-type(3) .planet_description {
    opacity: 1;
  }
  div.planets .solar_systm:nth-of-type(3) .planet_description p, div.planets .solar_systm:nth-of-type(3) .planet_description a {
    opacity: 0;
  }
  div.planets .solar_systm:nth-of-type(3) .planet_description h1, div.planets .solar_systm:nth-of-type(3) .planet_description h2 {
    position: relative;
    top: -330px;
    transition: all 0.5s 0s;
  }
  div.planets .solar_systm:nth-of-type(4) .planet {
    transform: translateZ(-6900px) translateY(0) rotatex(4deg) scalex(0.89);
    opacity: -1;
  }
  div.planets .solar_systm:nth-of-type(4) .planet_description {
    opacity: 1;
  }
  div.planets .solar_systm:nth-of-type(4) .planet_description p, div.planets .solar_systm:nth-of-type(4) .planet_description a {
    opacity: 0;
  }
  div.planets .solar_systm:nth-of-type(4) .planet_description h1, div.planets .solar_systm:nth-of-type(4) .planet_description h2 {
    position: relative;
    top: -330px;
    transition: all 0.5s 0s;
  }
  div.planets .solar_systm:nth-of-type(5) .planet {
    transform: translateZ(-9200px) translateY(0) rotatex(4deg) scalex(0.89);
    opacity: -2;
  }
  div.planets .solar_systm:nth-of-type(5) .planet_description {
    opacity: 1;
  }
  div.planets .solar_systm:nth-of-type(5) .planet_description p, div.planets .solar_systm:nth-of-type(5) .planet_description a {
    opacity: 0;
  }
  div.planets .solar_systm:nth-of-type(5) .planet_description h1, div.planets .solar_systm:nth-of-type(5) .planet_description h2 {
    position: relative;
    top: -330px;
    transition: all 0.5s 0s;
  }
  div.planets .solar_systm:nth-of-type(6) .planet {
    transform: translateZ(-11500px) translateY(0) rotatex(4deg) scalex(0.89);
    opacity: -3;
  }
  div.planets .solar_systm:nth-of-type(6) .planet_description {
    opacity: 1;
  }
  div.planets .solar_systm:nth-of-type(6) .planet_description p, div.planets .solar_systm:nth-of-type(6) .planet_description a {
    opacity: 0;
  }
  div.planets .solar_systm:nth-of-type(6) .planet_description h1, div.planets .solar_systm:nth-of-type(6) .planet_description h2 {
    position: relative;
    top: -330px;
    transition: all 0.5s 0s;
  }
  div.planets .solar_systm:nth-of-type(7) .planet {
    transform: translateZ(-13800px) translateY(0) rotatex(4deg) scalex(0.89);
    opacity: -4;
  }
  div.planets .solar_systm:nth-of-type(7) .planet_description {
    opacity: 1;
  }
  div.planets .solar_systm:nth-of-type(7) .planet_description p, div.planets .solar_systm:nth-of-type(7) .planet_description a {
    opacity: 0;
  }
  div.planets .solar_systm:nth-of-type(7) .planet_description h1, div.planets .solar_systm:nth-of-type(7) .planet_description h2 {
    position: relative;
    top: -330px;
    transition: all 0.5s 0s;
  }
  div.planets .solar_systm:nth-of-type(8) .planet {
    transform: translateZ(-16100px) translateY(0) rotatex(4deg) scalex(0.89);
    opacity: -5;
  }
  div.planets .solar_systm:nth-of-type(8) .planet_description {
    opacity: 1;
  }
  div.planets .solar_systm:nth-of-type(8) .planet_description p, div.planets .solar_systm:nth-of-type(8) .planet_description a {
    opacity: 0;
  }
  div.planets .solar_systm:nth-of-type(8) .planet_description h1, div.planets .solar_systm:nth-of-type(8) .planet_description h2 {
    position: relative;
    top: -330px;
    transition: all 0.5s 0s;
  }
  div.planets .solar_systm:nth-of-type(9) .planet {
    transform: translateZ(-18400px) translateY(0) rotatex(4deg) scalex(0.89);
    opacity: -6;
  }
  div.planets .solar_systm:nth-of-type(9) .planet_description {
    opacity: 1;
  }
  div.planets .solar_systm:nth-of-type(9) .planet_description p, div.planets .solar_systm:nth-of-type(9) .planet_description a {
    opacity: 0;
  }
  div.planets .solar_systm:nth-of-type(9) .planet_description h1, div.planets .solar_systm:nth-of-type(9) .planet_description h2 {
    position: relative;
    top: -330px;
    transition: all 0.5s 0s;
  }
  div.planets .solar_systm:nth-of-type(10) .planet {
    transform: translateZ(-20700px) translateY(0) rotatex(4deg) scalex(0.89);
    opacity: -7;
  }
  div.planets .solar_systm:nth-of-type(10) .planet_description {
    opacity: 1;
  }
  div.planets .solar_systm:nth-of-type(10) .planet_description p, div.planets .solar_systm:nth-of-type(10) .planet_description a {
    opacity: 0;
  }
  div.planets .solar_systm:nth-of-type(10) .planet_description h1, div.planets .solar_systm:nth-of-type(10) .planet_description h2 {
    position: relative;
    top: -330px;
    transition: all 0.5s 0s;
  }
  div.planets .solar_systm .planet .trajectory {
    transition: all 0.6s 0s;
    opacity: 0;
  }
  div.planets .solar_systm .planet.mercury {
    background: linear-gradient(to bottom, rgba(0, 0, 0, var(--planet-overlay-opacity)), rgba(0, 0, 0, var(--planet-overlay-opacity))),
    url("https://www.solarsystemscope.com/images/textures/full/2k_makemake_fictional.jpg");
    box-shadow: 0 -490px 150px black inset, 0 0px 130px 40px rgba(234, 205, 199, 0.6) inset, 0 0px 23px 4px rgba(234, 205, 199, 0.6) inset, 0 -10px 130px rgba(188, 143, 127, 0.6);
  }
  div.planets .solar_systm .planet.venus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, var(--planet-overlay-opacity)), rgba(0, 0, 0, var(--planet-overlay-opacity))),
    url("https://nasa3d.arc.nasa.gov/shared_assets/images/ven0aaa2/ven0aaa2-copy-428-321.jpg");
    box-shadow: 0 -490px 150px black inset, 0 0px 130px 40px #ffcb9c inset, 0 0px 23px 4px #ffcb9c inset, 0 -10px 130px #b85a07;
  }
  div.planets .solar_systm .planet.earth {
    background: linear-gradient(to bottom, rgba(0, 0, 0, var(--planet-overlay-opacity)), rgba(0, 0, 0, var(--planet-overlay-opacity))),
    url("https://img00.deviantart.net/04ef/i/2009/114/3/e/new_earth_texture_map_by_lightondesigns.jpg");
    box-shadow: 0 -490px 150px black inset, 0 0px 130px 40px #8cbaff inset, 0 0px 23px 4px #8cbaff inset, 0 -10px 130px #7894a9;
  }
  div.planets .solar_systm .planet.earth .trajectory.m {
    width: 1500px;
    height: 1500px;
    left: -150px;
    top: -110px;
  }
  div.planets .solar_systm .planet.earth .moon {
    left: 800px;
    top: -160px;
    transform: scale(0.45);
    background: url("https://img2.cgtrader.com/items/702173/682fad2a11/92k-moon-color-map-3d-model.jpg");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.earth .moon h3 {
    color: #26daaa;
  }
  div.planets .solar_systm .planet.mars {
    background: linear-gradient(to bottom, rgba(0, 0, 0, var(--planet-overlay-opacity)), rgba(0, 0, 0, var(--planet-overlay-opacity))),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/217233/mars_texture.jpg");
    box-shadow: 0 -490px 150px black inset, 0 0px 130px 40px #e86363 inset, 0 0px 23px 4px #e86363 inset, 0 -10px 130px #6b261a;
  }
  div.planets .solar_systm .planet.mars .moon h3 {
    color: #e55f45;
  }
  div.planets .solar_systm .planet.mars .deimos {
    left: 900px;
    top: -100px;
    transform: scale(0.45);
    background: url("https://www.jpl.nasa.gov/spaceimages/images/largesize/PIA07782_hires.jpg");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.mars .trajectory.d {
    width: 1770px;
    height: 1770px;
    left: -317px;
    top: -110px;
  }
  div.planets .solar_systm .planet.mars .trajectory.p {
    width: 1600px;
    height: 1600px;
    left: -200px;
    top: -160px;
  }
  div.planets .solar_systm .planet.mars .phoebos {
    left: 100px;
    top: -160px;
    transform: scale(0.5);
    background: url("https://www.jpl.nasa.gov/spaceimages/images/largesize/PIA07782_hires.jpg");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.jupiter {
    background: linear-gradient(to bottom, rgba(0, 0, 0, var(--planet-overlay-opacity)), rgba(0, 0, 0, var(--planet-overlay-opacity))),
    url("https://www.jpl.nasa.gov/spaceimages/images/largesize/PIA07782_hires.jpg");
    box-shadow: 0 -490px 150px black inset, 0 0px 130px 40px rgba(234, 205, 199, 0.6) inset, 0 0px 23px 4px rgba(234, 205, 199, 0.6) inset, 0 -10px 130px rgba(188, 143, 127, 0.6);
  }
  div.planets .solar_systm .planet.jupiter .moon h3 {
    color: orange;
  }
  div.planets .solar_systm .planet.jupiter .trajectory.lop {
    width: 1500px;
    height: 1500px;
    left: -210px;
    top: -189px;
  }
  div.planets .solar_systm .planet.jupiter .trajectory.eu {
    width: 1530px;
    height: 1530px;
    left: -165px;
    top: -130px;
  }
  div.planets .solar_systm .planet.jupiter .trajectory.ga {
    width: 1760px;
    height: 1760px;
    left: -360px;
    top: -114px;
  }
  div.planets .solar_systm .planet.jupiter .lo {
    left: 100px;
    top: -100px;
    transform: scale(0.4);
    background: url("http://stevealbers.net/albers/sos/jupiter/io/io_rgb_cyl.jpg");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.jupiter .europa {
    left: 400px;
    top: -210px;
    transform: scale(0.45);
    background: url("http://i.imgur.com/ZZBiHOH.jpg");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.jupiter .ganymede {
    left: 900px;
    top: -70px;
    transform: scale(0.4);
    background: url("https://vignette.wikia.nocookie.net/planet-texture-maps/images/1/14/Ganymede.jpg/revision/latest?cb=20180104001948");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.saturn {
    background: linear-gradient(to bottom, rgba(0, 0, 0, var(--planet-overlay-opacity)), rgba(0, 0, 0, var(--planet-overlay-opacity))),
    url("https://www.solarsystemscope.com/images/textures/full/2k_saturn.jpg");
    box-shadow: 0 -490px 150px black inset, 0 0px 130px 40px rgba(234, 205, 199, 0.6) inset, 0 0px 23px 4px rgba(234, 205, 199, 0.6) inset, 0 -10px 130px rgba(188, 143, 127, 0.6);
  }
  div.planets .solar_systm .planet.saturn .moon h3 {
    color: #b29d81;
  }
  div.planets .solar_systm .planet.saturn .trajectory.ti {
    width: 1500px;
    height: 1500px;
    left: -210px;
    top: -189px;
  }
  div.planets .solar_systm .planet.saturn .trajectory.di {
    width: 1530px;
    height: 1530px;
    left: -165px;
    top: -130px;
  }
  div.planets .solar_systm .planet.saturn .trajectory.enc {
    width: 1760px;
    height: 1760px;
    left: -360px;
    top: -114px;
  }
  div.planets .solar_systm .planet.saturn .titan {
    left: 100px;
    top: -100px;
    transform: scale(0.4);
    background: url("https://pre00.deviantart.net/bea3/th/pre/i/2017/057/7/f/titan_texture_map_8k_by_fargetanik-db0f8m0.png");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.saturn .dione {
    left: 400px;
    top: -210px;
    transform: scale(0.45);
    background: url("https://www.jpl.nasa.gov/spaceimages/images/wallpaper/PIA12577-640x350.jpg");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.saturn .enceladus {
    left: 900px;
    top: -70px;
    transform: scale(0.4);
    background: url("https://img.purch.com/w/660/aHR0cDovL3d3dy5zcGFjZS5jb20vaW1hZ2VzL2kvMDAwLzA0NC8yMzkvb3JpZ2luYWwvZW5jZWxhZHVzLW1hcC1jYXNzaW5pLmpwZw==");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.uranus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, var(--planet-overlay-opacity)), rgba(0, 0, 0, var(--planet-overlay-opacity))),
    url("https://img00.deviantart.net/957c/i/2017/165/4/9/uranus_texture_map_by_jcpag2010-db7yjwb.png");
    box-shadow: 0 -490px 150px black inset, 0 0px 130px 40px rgba(234, 205, 199, 0.6) inset, 0 0px 23px 4px rgba(234, 205, 199, 0.6) inset, 0 -10px 130px rgba(127, 188, 171, 0.6);
  }
  div.planets .solar_systm .planet.uranus .moon h3 {
    color: #8dcdd8;
  }
  div.planets .solar_systm .planet.uranus .trajectory.mir {
    width: 1500px;
    height: 1500px;
    left: -210px;
    top: -189px;
  }
  div.planets .solar_systm .planet.uranus .trajectory.ari {
    width: 1530px;
    height: 1530px;
    left: -165px;
    top: -130px;
  }
  div.planets .solar_systm .planet.uranus .trajectory.umb {
    width: 1760px;
    height: 1760px;
    left: -360px;
    top: -114px;
  }
  div.planets .solar_systm .planet.uranus .miranda {
    left: 100px;
    top: -100px;
    transform: scale(0.4);
    background: url("http://celestia.simulatorlabbs.com/CelSL/textures/medres/miranda.jpg");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.uranus .ariel {
    left: 400px;
    top: -210px;
    transform: scale(0.45);
    background: url("http://celestia.freedoors.org/Celestia-Doors/textures/medres/ariel.jpg");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.uranus .umbriel {
    left: 900px;
    top: -70px;
    transform: scale(0.4);
    background: url("http://celestia.freedoors.org/Celestia-Doors/textures/medres/titania.jpg");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.neptune {
    background: linear-gradient(to bottom, rgba(0, 0, 0, var(--planet-overlay-opacity)), rgba(0, 0, 0, var(--planet-overlay-opacity))),
    url("https://www.solarsystemscope.com/images/textures/full/2k_neptune.jpg");
    box-shadow: 0 -490px 150px black inset, 0 0px 130px 40px rgba(234, 205, 199, 0.6) inset, 0 0px 23px 4px rgba(234, 205, 199, 0.6) inset, 0 -10px 130px #2d4153;
  }
  div.planets .solar_systm .planet.neptune .moon h3 {
    color: #4f83e2;
  }
  div.planets .solar_systm .planet.neptune .trajectory.tri {
    width: 1500px;
    height: 1500px;
    left: -210px;
    top: -189px;
  }
  div.planets .solar_systm .planet.neptune .trajectory.pro {
    width: 1530px;
    height: 1530px;
    left: -165px;
    top: -130px;
  }
  div.planets .solar_systm .planet.neptune .trajectory.ner {
    width: 1760px;
    height: 1760px;
    left: -360px;
    top: -114px;
  }
  div.planets .solar_systm .planet.neptune .triton {
    left: 100px;
    top: -100px;
    transform: scale(0.4);
    background: url("https://img00.deviantart.net/b934/i/2016/198/b/0/triton_texture_map_14k_by_fargetanik-daac9tm.png");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.neptune .proteus {
    left: 400px;
    top: -210px;
    transform: scale(0.45);
    background: url("http://2.bp.blogspot.com/-NrsDNbSk8TU/VKmLHdOgw0I/AAAAAAAAHvY/dod1Kqv2Ta8/s1600/NereidTxt2.jpg");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.neptune .nereid {
    left: 900px;
    top: -70px;
    transform: scale(0.4);
    background: url("http://4.bp.blogspot.com/-3eyaVs4az74/VKmMpLo6FYI/AAAAAAAAHvs/zK5NTllQYnk/s1600/NereidTxt.jpg");
    z-index: -1;
    box-shadow: 0px -30px 30px 10px black inset;
  }
  div.planets .solar_systm .planet.pluto {
    background: url("https://pre00.deviantart.net/4677/th/pre/f/2015/314/4/e/pluto_map__2015_nov_10__by_snowfall_the_cat-d918tlb.png");
    box-shadow: 0 -490px 150px black inset, 0 0px 130px 40px rgba(234, 205, 199, 0.6) inset, 0 0px 23px 4px rgba(234, 205, 199, 0.6) inset, 0 -10px 130px #2d4153;
  }
  div.planets .solar_systm .planet_description {
    width: 620px;
    text-align: center;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    color: white;
    font-weight: 100;
    transition: all 0.4s 0s;
    text-transform: uppercase;
    z-index: 1;
  }
  div.planets .solar_systm .planet_description.mercury a {
    color: #E8927C;
  }
  div.planets .solar_systm .planet_description.mercury h2 {
    color: #f7dad3;
  }
  div.planets .solar_systm .planet_description.venus a {
    color: #b45d15;
  }
  div.planets .solar_systm .planet_description.venus h2 {
    color: #e99046;
  }
  div.planets .solar_systm .planet_description.earth a {
    color: #26daaa;
  }
  div.planets .solar_systm .planet_description.earth h2 {
    color: #7de9cc;
  }
  div.planets .solar_systm .planet_description.mars a {
    color: #e55f45;
  }
  div.planets .solar_systm .planet_description.mars h2 {
    color: #f2ac9e;
  }
  div.planets .solar_systm .planet_description.jupiter a {
    color: orange;
  }
  div.planets .solar_systm .planet_description.jupiter h2 {
    color: #ffc966;
  }
  div.planets .solar_systm .planet_description.saturn a {
    color: #b29d81;
  }
  div.planets .solar_systm .planet_description.saturn h2 {
    color: #d9cec0;
  }
  div.planets .solar_systm .planet_description.uranus a {
    color: #8dcdd8;
  }
  div.planets .solar_systm .planet_description.uranus h2 {
    color: #d9eef2;
  }
  div.planets .solar_systm .planet_description.neptune a {
    color: #4f83e2;
  }
  div.planets .solar_systm .planet_description.neptune h2 {
    color: #a7c1f0;
  }
  div.planets .solar_systm .planet_description.pluto a {
    color: #FF8732;
  }
  div.planets .solar_systm .planet_description.pluto h2 {
    color: #ffc398;
  }
  div.planets .solar_systm .planet_description h1,
  div.planets .solar_systm .planet_description h2,
  div.planets .solar_systm .planet_description p,
  div.planets .solar_systm .planet_description a {
    font-weight: 100;
    font-size: 10px;
    letter-spacing: 5px;
    margin: 0;
  }
  div.planets .solar_systm .planet_description h1 {
    letter-spacing: 16px;
    font-size: 34px;
  }
  div.planets .solar_systm .planet_description h2,
  div.planets .solar_systm .planet_description a {
    color: #fabfad;
  }
  div.planets .solar_systm .planet_description h2 {
    margin-top: 60px;
    margin-bottom: 6px;
  }
  div.planets .solar_systm .planet_description p {
    line-height: 26px;
    margin-top: 14px;
    opacity: 0.9;
    margin-bottom: 10px;
  }
  div.planets .solar_systm .planet_description a {
    color: #fea082;
    font-size: 11px;
    font-weight: 500;
    padding: 0 2px 5px 0px;
    border-bottom: 2px solid;
    transition: all 0.2s;
    position: relative;
    left: 0;
  }
  div.planets .solar_systm .planet_description a:hover {
    padding: 0 10px 5px 10px;
    left: -1px;
  }
  div.planets .solar_systm .planet_description a span {
    letter-spacing: 0;
    margin-left: -5px;
  }
  div.planets .panel {
    position: absolute;
    right: -520px;
    width: 300px;
    top: 0;
    height: 100vh;
    transition: all 0.2s;
    color: black;
    background: white;
    padding: 10px 60px 0px 60px;
    overflow: scroll;
  }
  div.planets .panel div.planets::-webkit-scrollbar {
    width: 1em;
  }
  div.planets .panel div.planets::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  div.planets .panel div.planets::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  div.planets .panel .profile {
    padding-top: 4px;
  }
  div.planets .panel .profile p {
    line-height: 10px;
  }
  div.planets .panel .profile p span {
    font-weight: 600;
    color: black;
  }
  div.planets .panel img {
    border-radius: 2px;
    width: 100%;
  }
  div.planets .panel h1 {
    text-transform: uppercase;
    font-weight: 100;
    margin: 0 0 0 0;
    letter-spacing: 3px;
    top: 0;
    padding: 49px 0 0 0;
    width: 100%;
    font-size: 20px;
  }
  div.planets .panel h1::after {
    width: 30px;
    height: 2px;
    background: black;
    display: block;
    content: "";
    margin-bottom: 30px;
    margin-top: 8px;
  }
  div.planets .panel h2 {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 30px;
  }
  div.planets .panel h2::after {
    width: 30px;
    height: 2px;
    background: black;
    display: block;
    content: "";
    margin-top: 8px;
  }
  div.planets .panel p {
    color: #a0a0a0;
    font-size: 12px;
    line-height: 20px;
  }

  .svg-icon {
    width: 2em;
    height: 2em;
  }
  
  .svg-icon path,
  .svg-icon polygon,
  .svg-icon rect {
    fill: white;
  }
  
  .svg-icon circle {
    stroke: white;
    stroke-width: 1;
  }