@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,500;1,600;1,700&display=swap');

@font-face {
	font-family: 'Marwan';
	src: url('../../src/app/assets/fonts/marwan.otf');
}

html {
    height: 100%;
    width: 100%;
	background-color: black;
}

body{
	overflow-x: hidden!important;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: black;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px rgba(239, 68, 68, 1);
}

::-webkit-scrollbar-thumb:hover {
	box-shadow: inset 0 0 15px rgba(239, 68, 68, 1);
}

.hidescroll::-webkit-scrollbar{
    display: none;
}

.hidescroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.h-screen-40 {
    height: 40vh;
}

/* .appChatbotContainer{ 
    border-radius: 5px;
    bottom: 40px;
    box-shadow: 5px 5px 13px rgba(91,81,81,.4);
    display: flex;
    justify-content: center;
    margin: 40px 0;
    position: fixed;
    right: 40px;
    z-index: 6;
}*/

.appChatbotButton{
    background-color: rgba(239, 68, 68, 1);
    border: none;
    border-radius: 25px;
    bottom: 15px;
    height: 50px;
    position: fixed;
    right: 40px;
    width: 50px;
    z-index: 6;
    display:flex;
    justify-content: center;
    align-items: center;
}

.appChatbotButtonIcon{
    fill: #fff;
    height: 25px;
}

.react-chatbot-kit-chat-message-container {
    overflow-y: scroll!important;
    overflow-x: hidden!important;
    background-color: black;
    
}

.react-chatbot-kit-chat-input {
    background-color: #efefef;
}

.react-chatbot-kit-chat-inner-container{
    height: auto!important;
}

.react-chatbot-kit-chat-input {
    border: none!important;
}

.react-chatbot-kit-chat-btn-send{
    background-color: rgba(239, 68, 68, 1)!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-chatbot-kit-chat-bot-message{
    background-color: rgba(239, 68, 68, 1)!important;
}

.react-chatbot-kit-chat-bot-message-arrow{
    border-right: 8px solid rgba(239, 68, 68, 1)!important;
}

.react-chatbot-kit-user-chat-message{
    background-color: #efefef!important;
}
.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-content > li > p {
    padding: .1rem 0;
    
  }
  .dropdown-content > li:hover{
    opacity: 0.5;
  }
  
  .dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #81818170}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  /* nav bar  */
  .toggle-button{
    position: absolute;
    right: 4rem;
    display:none;
    flex-direction:column;
    justify-content:space-between;
    width: 38px;
    height: 28px;

  }
  .toggle-button .bar{
    height: 3px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
  }

  @media screen and (max-width: 400px) {
    .toggle-button{
      top: 4rem;
      right: 1rem;
    }
  }

  @media screen and (max-width: 1023px) {
    .center-client{
      position: relative !important;
      left: 60% !important;
    }
  }

  @media screen and (min-width: 767px){
    .navbar-links ul{
      width:100%;
      flex-direction:row;
    }
  }
  /* Change nav bar depending on screen size */
  @media(max-width:767px){
    .toggle-button{
      display: flex;
    }
    /* nav bar container */
    .navbar-links{
      visibility: hidden;
      width:100%;
    }
    /*  */
    .navbar{
      flex-direction: column;
      align-items: flex-start;

    }
    /* the nav text for ul */
    .navbar-links ul{
      width:100%;
      flex-direction:column;
    }

    .navbar-links ul > li {
      z-index: 50;
    }
    
    .navbar-links li{
      align-self: center;
      margin: 0.5rem;

    }
    .navbar-links li a{
      padding: .5rem 1rem;
    }
    .navbar-links.active{
      display:flex;
    }

    .dropdown-content{
      left: 4rem;
      top: .2rem;
    }
  }
  
  .dropdown:hover .dropbtn {
    background-color: #17ee1e;
  }

  .global-link{
    color: rgb(255, 0, 0);
    text-decoration: none;
  }
  @media screen and (min-width: 768px) {
    .bg-links{
      background-color: transparent !important;
    }
  }
  
  /* Background */
  .background_trans{
    background-color: #1a1a1a42;
  }
  .background_stars{
    background-image: url("../../public/images/background.jpg");
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.78); 
    background-repeat: no-repeat;
    background-size:cover;
  }
  .background_notification{
    background-color: #6f2626;
    color:white;
  }

.background_aboutUs{
  background-image: url("../../public/images/background.jpg");
  box-shadow: inset 0 0 0 1000px #6f262620;
  background-repeat: no-repeat;
  background-size:cover;
  
}
.background_coreValues{
  background-image: url("../../public/images/background.jpg");
  box-shadow: inset 0 0 0 1000px #6f262630;
  background-repeat: no-repeat;
  background-size:cover;
  
}
.background_goals{
  background-image: url("../../public/images/background.jpg");
  box-shadow: inset 0 0 0 1000px #6f262640;
  background-repeat: no-repeat;
  background-size:cover;
  
}
.background_automationSolutions{
  background-image: url("../../public/images/background.jpg");
  box-shadow: inset 0 0 0 1000px #6f262650;
  background-repeat: no-repeat;
  background-size:cover;
  
}
.background_roboticsSolutions{
  background-image: url("../../public/images/background.jpg");
  box-shadow: inset 0 0 0 1000px #6f262660;
  background-repeat: no-repeat;
  background-size:cover;
  
}
.background_redtrans{
  background-color: #6f262645;
}
.background_greytrans{
  background-color: #1a1a1a42;
}
.background_review{
  background-color: #773232;
}

.background_edit{
  background-color: #514a4a82;
}
.background_greytrans{
  background-color: #515151e0;
}
.background_contact{
  background-color: #000000;
}
  /* User review */
  .column{
    float: left;
    width: 100%;
    padding:0 10px;

  }
  .row{
    margin-top:2rem ;
    margin-bottom:2rem ;
    
    /* display flex makes the background the same size */
    display:flex;
  }
  row::after{
    content: "";
    display: table;
    clear: both;
  }
  .card{
    box-shadow: 0 4px 4px 0(0,0,0,0.2);
    height: 100%;
    padding: 16px;
    margin: 15px;
    text-align:center;
    justify-content: center;
    background-color: #1a1a1a42;
    color: white;
    display: flex;
    flex-direction: column;
  }
  
  @media screen and (max-width:600px){
    .column{
      width:100%;
      display:block;
      margin-bottom:20;
    }
  }
  /* 5 star rating */
  .rate {
    align-self: center;
    height: 46px;
    padding: 0 10px;
}
.rate:not(:checked) > input {
    position:absolute;
    visibility: hidden;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
    
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}

.hide-all{
  visibility: hidden !important;
}



